import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import PrivateRoute from "./components/PrivateRoute";
import Logs from "./screens/Log";
import Login from "./screens/Login";
import ProAnalysis from "./screens/ProAnalysis";
import ProAnalysisCreate from "./screens/ProAnalysisCreate";
import Push from "./screens/Push";
import FcmTopic from "./screens/FcmTopic";
import Analytics from "./screens/Analytics";
import Sentiments from "./screens/Sentiments";
import SentimentsCreate from "./screens/SentimentsCreate";
import { loginFail, validateUser } from "./store/auth";
import HisseRadari from "./screens/HisseRadari";
import HisseRadariCreate from "./screens/HisseRadariCreate";
import VarantBulteni from "./screens/VarantBulteni";
import VarantBulteniCreate from "./screens/VarantBulteniCreate";

function App() {
  const { verified } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedToken = localStorage.getItem("APP_AUTH_TOKEN");

    if (storedToken) {
      dispatch(validateUser(storedToken));
    } else {
      dispatch(loginFail());
    }
  }, []);

  if (!verified) return <div className="text-center p-5">Yükleniyor...</div>;

  return (
    <div className="bg-gray-100 min-h-screen">
      <Router>
        <Header />

        <div className="container mx-auto">
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/sentiments/create">
              <SentimentsCreate />
            </PrivateRoute>
            <PrivateRoute path="/sentiments">
              <Sentiments />
            </PrivateRoute>
            <PrivateRoute path="/proanalysis/create">
              <ProAnalysisCreate />
            </PrivateRoute>
            <PrivateRoute path="/proanalysis">
              <ProAnalysis />
            </PrivateRoute>
            <PrivateRoute path="/hisseradari/create">
              <HisseRadariCreate />
            </PrivateRoute>
            <PrivateRoute path="/hisseradari">
              <HisseRadari />
            </PrivateRoute>
            <PrivateRoute path="/varantbulteni/create">
              <VarantBulteniCreate />
            </PrivateRoute>
            <PrivateRoute path="/varantbulteni">
              <VarantBulteni />
            </PrivateRoute>
            <PrivateRoute path="/analytics">
              <Analytics />
            </PrivateRoute>
            <PrivateRoute path="/logs">
              <Logs />
            </PrivateRoute>
            <PrivateRoute path="/fcmtopic">
              <FcmTopic />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Push />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
