import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { WHITELABELS } from "../constants";
import { logout } from "../store/auth";
import logo from "../assets/logo-icon.svg";


const Header = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    if (window.confirm("Emin misiniz?")) {
      dispatch(logout());
    }
  };

  if (!user) return null;

  return (
    <nav className="bg-white shadow mb-4">
      <div className="container mx-auto">
        <div className="flex flex-grow justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img src={logo} className="h-5" />{" "}
              <strong className="ml-2">{WHITELABELS[user.wl].title}</strong>
            </div>
            <div className="ml-12 flex items-center text-sm font-medium">
              <NavLink
                to="/"
                exact
                activeClassName="border-indigo-500 text-gray-900"
                className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
              >
                Bildirimler
              </NavLink>
              <NavLink
                to="/logs"
                activeClassName="border-indigo-500 text-gray-900"
                className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
              >
                Teknik Destek
              </NavLink>
              <NavLink
                  to="/fcmtopic"
                  activeClassName="border-indigo-500 text-gray-900"
                  className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
                >
                  Kanala Bildirim Gönder
                </NavLink>             
              {user.wl === "info" && (
                <>
                  <NavLink
                    to="/sentiments"
                    activeClassName="border-indigo-500 text-gray-900"
                    className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
                  >
                    Sentiment Algo
                  </NavLink>
                  <NavLink
                    to="/proanalysis"
                    activeClassName="border-indigo-500 text-gray-900"
                    className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
                  >
                    Analiz PRO
                  </NavLink>
                  <NavLink
                    to="/hisseradari"
                    activeClassName="border-indigo-500 text-gray-900"
                    className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
                  >
                    Hisse Radarı
                  </NavLink>
                  <NavLink
                    to="/varantbulteni"
                    activeClassName="border-indigo-500 text-gray-900"
                    className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
                  >
                    Varant Bülteni
                  </NavLink>
                </>
              )}
              {user.wl === "ak" && (
                <NavLink
                  to="/analytics"
                  activeClassName="border-indigo-500 text-gray-900"
                  className="border-b-2 text-gray-500 hover:text-gray-700 flex h-full items-center px-3"
                >
                  Versiyon Takibi
                </NavLink>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <span className="bg-gray-200 flex py-1 px-2 text-xs rounded-full mr-3 text-gray-700">
              {user.email}
            </span>

            <a
              onClick={handleLogout}
              className="cursor-pointer text-gray-500 hover:border-gray-300 hover:text-gray-700 text-xs"
            >
              Çıkış yap
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
